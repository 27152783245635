<template>
  <v-radio-group v-if="radio === true" :label="label" :hint="hint"
                 :value="value"
                 @change="$emit('input', $event)"
                 :rules="rules">
    <div class="d-flex align-center justify-start">
      <v-radio v-for="item of items" :value="item.key" :label="item.valor" :key="item.key" class="ma-0 pa-0 mr-2"/>
    </div>
  </v-radio-group>
  <v-autocomplete
      v-else
      :multiple="multiple"
      :label="label"
      :value="value"
      :rules="rules"
      :small-chips="chip"
      :deletable-chips="chip"
      :items="items"
      :loading="internalLoading || loading === true"
      item-text="valor"
      :dense="!chip"
      :solo="boxStyle"
      :error-messages="!error ? [] : ['Não foi possível carregar os itens']"
      :disabled="internalLoading || disabled"
      @input="$emit('input', $event)"
      :hint="hint"
      :persistent-hint="hint != null"
      :clearable="hiddenClear !== true"
      :hide-details="hideDetails"
      item-value="key">

    <template v-slot:append>
      <slot name="append"/>
      <span v-if="error">
        <v-icon color="error">mdi-alert</v-icon>
        </span>
    </template>

    <template v-slot:append-outer v-if="hiddenRefresh !== true">
      <v-tooltip top left>
        <template v-slot:activator="{on}">
          <v-btn v-on="on" icon @click="refresh">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>
          Clique aqui para buscar novamente os itens
        </span>
      </v-tooltip>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from 'lodash.debounce';
import {mapActions} from "vuex";

export default {
  name: "LookupDominio",
  props: {
    /** @type {import('vue').PropOptions<{require('../../../api/ItemDeDominio')}>}  */
    type: {
      type: String
    },
    multiple: {
      type: Boolean
    },
    chip: {
      type: Boolean
    },
    radio: {
      type: Boolean | Object
    },
    value: {
      type: String | Number
    },
    label: {
      type: String
    },
    rules: {
      type: Array
    },
    hint: {
      type: String
    },
    hiddenRefresh: {
      type: Boolean
    },
    hiddenClear: {
      type: Boolean
    },
    boxStyle: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    hideDetails: {
      type: Boolean
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      items: [],
      internalLoading: true,
      error: false
    }
  },
  methods: {
    ...mapActions('itemsDominio', {'fetchItemsDeDominio': 'fetch'}),
    async fetch(forceRefresh) {
      try {
        this.error = false;
        this.internalLoading = true;
        this.items = await this.fetchItemsDeDominio({
          itemDominio: this.type,
          forceRefresh
        });
      } catch (err) {
        this.error = true;
        console.error(err);
      } finally {
        this.internalLoading = false;
      }
    },
    refresh: debounce(function () {
      this.fetch(true);
    }, 300)
  },
  mounted() {
    this.fetch();
  }
}
</script>

<style scoped lang="scss">

::v-deep {

  .v-input__append-inner {
    cursor: pointer;
  }

  .v-input__append-outer {
    margin-left: 0;
    padding: 0 9px;
    border-bottom: thin solid rgba(0, 0, 0, 0.42);
    margin-top: -10px;
    //height: 29px;

  }
}
</style>